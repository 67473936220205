import {createContext, useState, useContext} from 'react';
import {TonConnectUIProvider} from "@tonconnect/ui-react";

const AuthContext = createContext(undefined);

export const AuthAPI = ({children}) => {
    const [isLogged, setIsLogged] = useState(false);

    return (
        <TonConnectUIProvider manifestUrl="https://raw.githubusercontent.com/VectorBravo-cr/ton-contracter/main/w3mspacebot.json">
            <AuthContext.Provider value={{isLogged, setIsLogged}}>
                {children}
            </AuthContext.Provider>
        </TonConnectUIProvider>
    )
}

export const useAuth = () => useContext(AuthContext);